
<template>
    <div class="container">
        <div class="title">
            <span class="title-left">
                <img src="../../../assets/img/company/permaicon.png" alt="" style="margin-right: 8px" />
                Performance
            </span>
        </div>
        <div class="footer" v-loading="loading" v-show="profitablitylist.length > 0">
            <div class="ecahrts-header">
                <span class="title-left">
                    <img src="../../../assets/img/company/Lossicon.png" alt="" style="margin-right: 8px" />
                    Profit & Losst
                </span>

                <div class="head_loging" :class="loginStatus">
                    <el-switch active-color="#13ce66" v-model="loginStatus"></el-switch>
                </div>
            </div>
            <div v-show="loginStatus" class="ecahrtstu">
                <Table ref="table" :profitablitylist="profitablitylist" :yearslist="yearslist1" :untia="untia"></Table>
            </div>
        </div>

        <div class="computer_right_cont_box" v-if="parentTablelist.length > 0">
            <div class="title" style="margin-top: 20px">
                <span class="title-left">
                    <img src="../../../assets/img/company/Lossicon.png" style="margin-right: 8px" />
                    Related Profit & Losst
                </span>
            </div>
            <div class="infinite-list-wrapper" v-infinite-scroll="infiniteScroll" style="overflow: auto" :infinite-scroll-disabled="false" :infinite-scroll-distance="5">
                <!-- <div v-infinite-scroll="load" infinite-scroll-disabled="disabled"> -->
                <div class="footer" v-for="(item, index) in parentTablelist" :key="index" v-loading="item.loading2">
                    <div class="ecahrts-header">
                        <span class="title-left">
                            <img src="../../../assets/img/company/Lossicon.png" alt="" style="margin-right: 8px" />
                            <span v-show="!showTranslate" style="color: #1290c9; cursor: pointer; margin-right: 5px; width: 580px" class="line_1" @click="setid(item)">{{ item.nameEn }}</span>
                            <span v-show="showTranslate" style="color: #1290c9; cursor: pointer; margin-right: 5px; width: 580px" class="line_1" @click="setid(item)">{{ item.nameCn }}</span>
                            Profit & Losst
                        </span>
                        <span style="display: flex; justify-content: flex-start; width: 280px">relations:{{ item.typeEn }}</span>

                        <div class="head_loging">
                            <el-switch active-color="#13ce66" :value="loginStatus1[index]" @change="loginPop(index, item.aaaid, item.nameEn)"></el-switch>
                        </div>
                    </div>
                    <div v-show="loginStatus1[index]" class="ecahrtstu">
                        <Table :profitablitylist="item.profitablitylist1" :yearslist="item.yearslist" :ida="id3a" :untia="untia"></Table>
                    </div>
                </div>
            </div>
        </div>
        <div class="back-to-top" v-show="showBackToTop" @click="scrollToTop">
            <button class="el-icon-top gaodu"></button>
        </div>
        <el-dialog :visible.sync="newDialog" center :close-on-click-modal="false" @close="closeDialog" custom-class="dialog_normalsuo" :append-to-body="true">
            <div>
                <!-- <div class="dialog_detail_title">Pay attention to</div> -->
                <div class="detail_cont">
                    <div class="detail_cont">
                        <div class="detail_cont_text">
                            • Financial performance is a paid feature, this allows you to view all the available data including financial details and credit assessment on the company. The cost of this update is 10 units per company.<br> Subject company's financial performance data is often collected from various public data sources as well as local credit reporting agencies. This data is also calculated, modeled and forecasted based on market and industry research and a variety of available data. This data is for your reference only. <br>If the available online data is incomplete or out of date, an update for a fresh investigated business credit report will be automatically ordered via our local credit reporting agencies. It normally takes about 72 hours for an update report delivery. A business credit
                        report often contains details about the subject company's actual operations, financial performance, credit analysis and assessment.
                        </div>
                    </div>

                    <!-- <div>• Shareholder penetration is a paid feature, this will cost 2 units.</div> -->
                </div>
            </div>
            <div class="flex_center_between_box">
                <el-button class="btn_export" id="ok" @click="onSubmitkoudian()">OK</el-button>
                <el-button class="btn_export" @click="onSubmitquxiao()">Buy credits</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import crypto from '@/utils/crypto';
import { getFinancials, getrelatedCompanylist, getCompanyDetailMenuList, ordersAdd } from '../../../api/companyApi';
import Table from './components/table.vue';
import { mapGetters } from 'vuex';
import { mixins1 } from '@/mixins/index';
export default {
    mixins: [mixins1],
    components: { Table },
    data() {
        return {
            yearslist: [],
            yearslist1: [],
            untia: '',
            id3a: [],
            loading: true,
            loading1: false,
            profitablitylist: [],

            loginStatus: true, //登录状态
            pageChild: {
                pageNo: 1,
                pageSize: 5,
                total: 0,
            },
            page: {
                pageNo: 1,
                pageSize: 10,
                total: 0,
            },
            icon: '',
            parentTablelist: [],
            loginStatus1: [],
            noMore: false, // 控制滚动禁用
            routeLoad: false, // 控制滚动禁用
            showBackToTop: false,
            //提示弹框
            newDialog: false,
            indexclose: '',
            Obj: {
                id: '',
                name: '',
                index: '',
            },
            params: {},
            onepower:""
        };
    },
    computed: {
        ...mapGetters({
            cardlist: 'performance/cardlist1',
        }),
        showTranslate: {
            get() {
                return this.$parent.indexIsTranslate;
            },
            set() {},
        },
        curPagePower: {
            get() {
                return JSON.parse(this.$parent.powerToPage);
            },
            set() {},
        },
    },
    watch: {
        $route: {
            handler(val, oldval) {
                this.Indexy = val.path.replace('/', ''); //当前活动路由
            },
            // 深度观察监听
            deep: true,
        },
    },
    mounted() {
        this.getlist();
        this.getrelateList();
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeUnmount() {
        // 移除页面滚动事件的监听
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        closeDialog() {
            const okButton = document.getElementById('ok');

            okButton.removeEventListener('click', this.onSubmitkoudian);
            this.loginStatus1[this.indexclose] = false;
            this.parentTablelist[this.indexclose].loading2 = false;
            this.$set(this.parentTablelist, this.indexclose, this.parentTablelist[this.indexclose]);
            this.newDialog = false;
        },
        // 提示扣点
        onSubmitkoudian() {
            let that = this;
            let id = this.Obj.id;
            let nameEn = this.Obj.name;
            this.getOrdersAdd(2, id, nameEn).then(res => {
                if (res) {
                    if(!that.onepower){
                        that.getOrdersAdd(1, id, nameEn).then(res => {});
                    }   
                    let params = this.params;
                    getFinancials(params).then(res => {
                        if (!res.code) {
                            let index = that.Obj.index;
                            that.parentTablelist[index].profitablitylist1 = [];
                            that.parentTablelist[index].loading2 = false;
                        }
                        let index = that.Obj.index;
                  

                        let data = JSON.parse(crypto.decrypt(res));
                        let { pl, yearList, unit } = data.data;
                        that.parentTablelist[index].yearslist = yearList;
                        that.id3a = yearList;
                        that.parentTablelist[index].profitablitylist1 = pl;
                      
                        that.untia = unit;
                        that.loginStatus1[index] = true;
                        that.parentTablelist[index].loading2 = false;
                        that.$set(this.parentTablelist, index, that.parentTablelist[index]);
                    });
                } else {
                    let index = that.Obj.index;
                    that.loginStatus1[index] = false;
                    that.parentTablelist[index].loading2 = false;
                    that.$set(this.parentTablelist, index, that.parentTablelist[index]);
                }
            });
            this.newDialog = false;
        },
        // 取消
        onSubmitquxiao() {
            // this.newDialog = false;
            // this.$emit('closeCloseDialog', false);
            let that = this;
            let userinfo = JSON.parse(localStorage.getItem('userInfo')).userInfo;

            console.log(`userinfo`, userinfo);
            let routeData = '';
            setTimeout(function () {
                if (userinfo.type == '3') {
                    routeData = that.$router.resolve({
                        path: '/account/quota',
                    });
                } else {
                    routeData = that.$router.resolve({
                        path: '/account/addCredit',
                    });
                }

                window.open(routeData.href, '_blank');
            }, 1000);
        },
        // 点击跳转
        setid(item) {
            console.log(item);
            // sessionStorage.setItem('company', JSON.stringify(row));
            let routeData = this.$router.resolve({
                path: '/company/companysnapshot?id3a=' + item.aaaid + '&companyCountry=' + '',
            });

            window.open(routeData.href, '_blank');
        },
        getlist() {
            const params = {
                id3a: this.$route.query.id3a,
                companyCountry: this.$route.query.companyCountry,
                // id3a: JSON.parse(sessionStorage.getItem("company")).aaa_id,
                // companyCountry: JSON.parse(sessionStorage.getItem("company")).company_country,
                // companyCountry: JSON.parse(sessionStorage.getItem("comany")).company_country,
            };
            getFinancials(params).then(res => {
                this.loading = false;
                let data = JSON.parse(crypto.decrypt(res));
                let { pl, yearList, unit } = data.data;
                this.untia = unit;
                this.profitablitylist = pl;
                this.yearslist1 = yearList;
            });
        },
        onPageChangeList() {},
        tab(item) {},
        handleChange(val) {
            this.icon = val;
        },
        async loginPop(index, id, nameEn) {
            this.indexclose = index;
            this.$set(this.loginStatus1, index, !this.loginStatus1[index]);
            if (this.loginStatus1[index]) {
                this.parentTablelist[index].loading2 = true;
                const params = {
                    id3a: id,
                    // companyCountry: 'US',
                    // id3a: JSON.parse(sessionStorage.getItem("company")).aaa_id,
                    // companyCountry: JSON.parse(sessionStorage.getItem("company")).company_country,
                    // companyCountry: JSON.parse(sessionStorage.getItem("comany")).company_country,
                };

                let res = await getCompanyDetailMenuList('code=' + id + '&type=' + 7);
                let power = res.data[5].list[2].power;
                this.onepower=res.data[5].list[0].power
                if (power) {
                    getFinancials(params).then(res => {
                        if (!res.code) {
                            this.parentTablelist[index].profitablitylist1 = [];
                            this.parentTablelist[index].loading2 = false;
                        }
                        this.parentTablelist[index].loading2 = false;

                        let data = JSON.parse(crypto.decrypt(res));
                        console.log(data);

                        let { pl, yearList, unit } = data.data;
                        this.parentTablelist[index].yearslist = yearList;
                        this.id3a = yearList;
                        this.parentTablelist[index].profitablitylist1 = pl;
                        console.log(this.parentTablelist);
                        this.untia = unit;
                    });
                } else {
                    const okButton = document.getElementById('ok');
                    this.newDialog = true;
                    this.params = params;
                    this.Obj.name = nameEn;
                    this.Obj.id = id;
                    this.Obj.index = index;
                    // okButton.addEventList
                 
                    // this.getOrdersAdd(2, id, nameEn).then(res => {
                    //     if (res) {
                    //         getFinancials(params).then(res => {
                    //             if (!res.code) {
                    //                 this.parentTablelist[index].profitablitylist1 = [];
                    //                 this.parentTablelist[index].loading2 = false;
                    //             }
                    //             this.parentTablelist[index].loading2 = false;

                    //             let data = JSON.parse(crypto.decrypt(res));
                    //             console.log(data);

                    //             let { pl, yearList, unit } = data.data;
                    //             this.parentTablelist[index].yearslist = yearList;
                    //              this.id3a = yearList;
                    //             this.parentTablelist[index].profitablitylist1 = pl;
                    //             console.log(this.parentTablelist);
                    //             this.untia = unit;
                    //         });
                    //     } else {
                    //         this.loginStatus1[index] = false;
                    //         this.parentTablelist[index].loading2 = false;
                    //         this.$set(this.parentTablelist, index, this.parentTablelist[index]);
                    //     }
                    // });
                }
            }
            console.log(``, id);
        },
        async getOrdersAdd(type, aid, nameEn) {
            const id3a = aid;

            //1  :线上报告
            let params = {
                companyName: nameEn, //必须
                aaaId: id3a, //必须
                // companyCountry: companyCountry, //必须
                method: '0', //支付方式  0：帐号余额 1：支付宝 2：微信 ，3 国际万事达卡 支付 传  0   //必须
                methodType: '0', //支付类型： 1 免费额度 ，0 充值额度       //(非必须)
                type: type, //订单类型 //必须
            };
            let status = '';

            await ordersAdd(params).then(result => {
                let data = result;
                if (data && data.code) {
                    status = true;
                    if (data.msg != 'Saved successfully') {
                        this.$message({
                            message: data.msg,
                            type: 'success',
                            // duration: 5000,
                        });
                    }
                } else {
                    this.$message({
                        message: data.msg,
                        type: 'error',
                        // duration: 5000,
                    });
                    let that = this;
                    let userinfo = JSON.parse(localStorage.getItem('userInfo')).userInfo;

                    let routeData = '';
                    setTimeout(function () {
                        if (userinfo.type == '3') {
                            routeData = that.$router.resolve({
                                path: '/account/quota',
                            });
                        } else {
                            routeData = that.$router.resolve({
                                path: '/account/addCredit',
                            });
                        }

                        window.open(routeData.href, '_blank');
                    }, 2000);

                    status = false;
                }
            });
            this.getliulantime(params,'Profit&Loss')
            return status;
        },
        infiniteScroll() {
            this.routeLoad = false;
        },
        getrelateList() {
            getrelatedCompanylist({ id3a: this.$route.query.id3a, page: this.page.pageNo, pageSize: this.page.pageSize, resourceType: 1 }).then(res => {
                if (res && res.code) {
                    let list = res.data.rows;
                    this.parentTablelist = res.data.rows;
                    this.routeLoad = false;
                    this.parentTablelist.map(item => {
                        item.profitablitylist1 = [];
                        item.loading2 = false;
                        item.yearslist = [];
                    });
                }
            });
        },
        handleScroll() {
            this.showBackToTop = window.pageYOffset > 1000;
        },
        scrollToTop() {
            // 使用scrollTo()方法将页面滚动到顶部
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        },
    },
};
</script>
<style scoped lang="less">
.cont_m {
    margin-top: 30px;
    margin-bottom: 80px;
}

.Company Snapshot {
}

.Country Data {
}

.title {
    display: flex;
    align-items: center;

    justify-content: space-between;
    // margin-top: 69px;

    .title-left {
        display: flex;
        font-size: 16px;
        font-family: Arial-BoldMT, Arial;
        font-weight: normal;
        color: #022955;
    }
}

.table {
    margin-top: 20px;
}

.el-card.is-always-shadow {
    box-shadow: none;
}

// 卡片
/deep/ .el-card {
    // border: 1px solid #EBEEF5;
    background-color: #fff;
    color: #303133;
    transition: 0.3s;
    border: none;

    .el-card__body {
        padding: 3px;
        display: flex;
        flex-wrap: wrap;

        .item {
            display: flex;
            padding: 15px 35px 15px 20px;

            height: 70px;
            background: #ffffff;
            box-shadow: 0px 2px 6px 1px rgba(10, 33, 57, 0.14);
            border-radius: 14px;
            margin: 0 20px 20px 0;

            .card-text {
                margin-left: 10px;
                display: flex;
                width: 110px;
                flex-wrap: wrap;

                .text-head {
                    width: 110px;
                    height: 16px;
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #8497ab;
                }

                .text-foot {
                    width: 110px;
                    height: 18px;
                    font-size: 12px;
                    font-family: Arial-BoldMT, Arial;
                    font-weight: normal;
                    color: #022955;
                    margin-top: 8px;
                }
            }
        }
    }
}

.footer {
    margin-top: 20px;
    padding: 20px;

    overflow: hidden;
    background: #ffffff;
    box-shadow: 0px 2px 6px 1px rgba(10, 33, 57, 0.14);
    border-radius: 14px;
}

.ecahrts-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title-left {
        display: flex;
    }
}

.ecahrtstu {
    margin-top: 16px;
}
/deep/ .table-biao {
    height: auto;
    overflow-y: auto;
    background: #ffffff;
    border-radius: 14px;
    border: 1px solid #e7eaed;
}
.infinite-list-wrapper {
    // height: 500px;
    padding: 4px;
}
.line_1 {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.back-to-top {
    z-index: 999;
    position: fixed;
    transition: opacity 0.4s ease-in-out 0s;
    opacity: 1;
    box-sizing: border-box;
    bottom: 86px;
    right: 45px;
    cursor: pointer;
    /* background-color: #1290c9; */
}

.gaodu {
    height: 40px;
    width: 40px;
    border-radius: 6px;
    background-color: #1290c9;
    color: #fff;
    font-size: 20px;
}
.container {
    margin-top: 69px;
}
.detail_cont {
    padding: 0px 10px 10px;
}
.detail_cont_text {
    font-size: 14px;
    color: #022955;
}
@media (max-width: 821px) {


.container{
  margin-top: 20px !important;
}

}
</style>